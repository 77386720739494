export const proposedVenueOptions = [
  {
    label: "サカエヤ・ホール リビングルーム",
    value: "サカエヤ・ホール リビングルーム",
  },
  {
    label: "サカエヤ・ホール 一般葬式場なでしこ",
    value: "サカエヤ・ホール 一般葬式場なでしこ",
  },
  {
    label: "サカエヤ・ホール 家族葬式場しらさぎ",
    value: "サカエヤ・ホール 家族葬式場しらさぎ",
  },
  {
    label: "さがみ野駅前ファミリーホール 式場",
    value: "さがみ野駅前ファミリーホール",
  },
  { label: "しぶさわホール 式場", value: "しぶさわホール式場" },
  { label: "セレモニーホール多摩 式場", value: "セレモニーホール多摩 式場" },
  {
    label: "セレモニーホール多摩 特別貴賓室",
    value: "セレモニーホール多摩 特別貴賓室",
  },
  {
    label: "セレモニーホール多摩 竹葉ホール",
    value: "セレモニーホール多摩 竹葉ホール",
  },
  {
    label: "ファミリーホール二宮 ファミリーホール",
    value: "ファミリーホール二宮 ファミリーホール",
  },
  {
    label: "ファミリーホール二宮 リビングホール",
    value: "ファミリーホール二宮 リビングホール",
  },
  {
    label: "ファミリーホール厚木 式場",
    value: "ファミリーホール厚木 式場",
  },
  {
    label: "ファミリーホール大井松田 式場",
    value: "ファミリーホール大井松田 式場",
  },
  {
    label: "ファミリーホール大和 2階式場やまざくら",
    value: "ファミリーホール大和 2階式場やまざくら",
  },
  {
    label: "ファミリーホール大和 3階リビングルーム",
    value: "ファミリーホール大和 3階リビングルーム",
  },
  {
    label: "ファミリーホール小田原 式場",
    value: "ファミリーホール小田原 式場",
  },
  {
    label: "ファミリーホール日野 式場",
    value: "ファミリーホール日野 式場",
  },
  {
    label: "ファミリーホール日野本町 式場",
    value: "ファミリーホール日野本町 式場",
  },
  {
    label: "ファミリーホール橋本 1ホール",
    value: "ファミリーホール橋本 1ホール",
  },
  {
    label: "ファミリーホール湘南海岸 ファミリーホール",
    value: "ファミリーホール湘南海岸 ファミリーホール",
  },
  {
    label: "ファミリーホール湘南海岸 リビングルーム",
    value: "ファミリーホール湘南海岸 リビングルーム",
  },
  {
    label: "ファミリーホール湘南海岸 竹葉ホール",
    value: "ファミリーホール湘南海岸 竹葉ホール",
  },
  {
    label: "ファミリーホール綾瀬 1階式場かりん",
    value: "ファミリーホール綾瀬 1階式場かりん",
  },
  {
    label: "ファミリーホール綾瀬 2階式場すいれん",
    value: "ファミリーホール綾瀬 2階式場すいれん",
  },
  {
    label: "ファミリーホール聖蹟桜ヶ丘 式場",
    value: "ファミリーホール聖蹟桜ヶ丘 式場",
  },
  {
    label: "ファミリーホール茅ヶ崎 ビジテーションルーム",
    value: "ファミリーホール茅ヶ崎 ビジテーションルーム",
  },
  {
    label: "ファミリーホール茅ヶ崎 ファミリーホール",
    value: "ファミリーホール茅ヶ崎 ファミリーホール",
  },
  {
    label: "ファミリーホール藤沢大庭 家族葬式場彩(いろどり)",
    value: "ファミリーホール藤沢大庭 家族葬式場彩(いろどり)",
  },
  {
    label: "ファミリーホール藤沢大庭 家族葬式場燈(あかり)",
    value: "ファミリーホール藤沢大庭 家族葬式場燈(あかり)",
  },
  {
    label: "ファミリーホール高尾 式場",
    value: "ファミリーホール高尾 式場",
  },
  {
    label: "伊勢原総合ホール リビングルーム",
    value: "伊勢原総合ホール リビングルーム",
  },
  {
    label: "伊勢原総合ホール 一般葬式場大山",
    value: "伊勢原総合ホール 一般葬式場大山",
  },
  {
    label: "伊勢原総合ホール 家族葬式場丹沢",
    value: "伊勢原総合ホール 家族葬式場丹沢",
  },
  {
    label: "伊勢原駅南口ファミリーホール 2階式場ききょう",
    value: "伊勢原駅南口ファミリーホール 2階式場ききょう",
  },
  {
    label: "伊勢原駅南口ファミリーホール 3階式場しいの木",
    value: "伊勢原駅南口ファミリーホール 3階式場しいの木",
  },
  {
    label: "伊勢原駅南口ファミリーホール リビングルーム",
    value: "伊勢原駅南口ファミリーホール リビングルーム",
  },
  {
    label: "八王子市斎場 第一式場",
    value: "八王子市斎場 第一式場",
  },
  {
    label: "八王子市斎場 第二式場",
    value: "八王子市斎場 第二式場",
  },
  {
    label: "八王子北口ファミリーホール　3階セレモニ―ホール",
    value: "八王子北口ファミリーホール　3階セレモニ―ホール",
  },
  {
    label: "八王子北口ファミリーホール　4階ファミリーホール",
    value: "八王子北口ファミリーホール　4階ファミリーホール",
  },
  {
    label: "八王子南口総合ホール 2階式場",
    value: "八王子南口総合ホール2階式場",
  },
  {
    label: "八王子南口総合ホール リビングルーム",
    value: "八王子南口総合ホールリビングルーム",
  },
  {
    label: "八王子滝山ファミリーホール 2階昴",
    value: "八王子滝山ファミリーホール 2階昴",
  },
  {
    label: "八王子滝山ファミリーホール 2階暁",
    value: "八王子滝山ファミリーホール 2階暁",
  },
  {
    label: "八王子総合ホール 1階式場いちょう",
    value: "八王子総合ホール 1階式場いちょう",
  },
  {
    label: "八王子総合ホール 2階式場やまゆり",
    value: "八王子総合ホール 2階式場やまゆり",
  },
  {
    label: "南多摩斎場 第一式場",
    value: "南多摩斎場第一式場",
  },
  {
    label: "南多摩斎場 第二式場",
    value: "南多摩斎場第二式場",
  },
  {
    label: "南多摩斎場 第三式場",
    value: "南多摩斎場第三式場",
  },
  {
    label: "南多摩総合ホール けやき",
    value: "南多摩総合ホール けやき",
  },
  {
    label: "南多摩総合ホール さくら",
    value: "南多摩総合ホール さくら",
  },
  {
    label: "南多摩総合ホール もみじ",
    value: "南多摩総合ホールもみじ",
  },
  {
    label: "厚木市斎場 式場",
    value: "厚木市斎場",
  },
  {
    label: "大和斎場 第一式場・第二式場",
    value: "大和斎場 第一式場・第二式場",
  },
  {
    label: "大和斎場 第三式場",
    value: "大和斎場 第三式場",
  },
  {
    label: "大和斎場 第四式場",
    value: "大和斎場 第四式場",
  },
  {
    label: "大和総合ホール  4階式場",
    value: "大和総合ホール  4階式場",
  },
  {
    label: "大和総合ホール 1階リビングルーム",
    value: "大和総合ホール 1階リビングルーム",
  },
  {
    label: "大和総合ホール 2階式場",
    value: "大和総合ホール 2階式場",
  },
  {
    label: "大和総合ホール 3階式場",
    value: "大和総合ホール 3階式場",
  },
  {
    label: "小田原式典総合ホール  家族葬式場",
    value: "小田原式典総合ホール  家族葬式場",
  },
  {
    label: "小田原式典総合ホール 一般葬式場",
    value: "小田原式典総合ホール 一般葬式場",
  },
  {
    label: "小田急相模原駅前ファミリーホール 2階セレモニ―ホール",
    value: "小田急相模原駅前ファミリーホール 2階セレモニ―ホール",
  },
  {
    label: "小田急相模原駅前ファミリーホール 4階多目的ホール",
    value: "小田急相模原駅前ファミリーホール 4階多目的ホール",
  },
  {
    label: "平塚南口ファミリーホール 式場",
    value: "平塚南口ファミリーホール 式場",
  },
  {
    label: "平塚斎場 リビングルーム",
    value: "平塚斎場 リビングルーム",
  },
  {
    label: "平塚斎場 一般葬式場くすのき・いちょう",
    value: "平塚斎場 一般葬式場くすのき・いちょう",
  },
  {
    label: "平塚斎場 大規模葬式場くろまつ",
    value: "平塚斎場 大規模葬式場くろまつ",
  },
  {
    label: "平塚斎場 家族葬式場なでしこ",
    value: "平塚斎場 家族葬式場なでしこ",
  },
  {
    label: "平塚西セレモニーホール 式場",
    value: "平塚西セレモニーホール 式場",
  },
  {
    label: "平塚西セレモニーホール 紫苑邸",
    value: "平塚西セレモニーホール 紫苑邸",
  },
  {
    label: "座間ホール  1階ロイヤルルーム",
    value: "座間ホール  1階ロイヤルルーム",
  },
  {
    label: "座間ホール 2階ホール",
    value: "座間ホール 2階ホール",
  },
  {
    label: "日野会館　高倉総合ホール 小式場かすみ",
    value: "日野会館 高倉総合ホール 小式場かすみ",
  },
  {
    label: "日野会館　高倉総合ホール 式場",
    value: "日野会館　高倉総合ホール 式場",
  },
  {
    label: "横浜市北部斎場 式場",
    value: "横浜市北部斎場 式場",
  },
  {
    label: "横浜町田ファミリーホール 1階式場",
    value: "横浜町田ファミリーホール 1階式場",
  },
  {
    label: "横浜町田ファミリーホール 2階リビングルーム",
    value: "横浜町田ファミリーホール 2階リビングルーム",
  },
  {
    label: "橋本総合ホール 1階式場",
    value: "橋本総合ホール 1階式場",
  },
  {
    label: "橋本総合ホール 2階式場",
    value: "橋本総合ホール 2階式場",
  },
  {
    label: "橋本総合ホール 3階リビングルーム",
    value: "橋本総合ホール 3階リビングルーム",
  },
  {
    label: "海老名セレモニーホール 1階リビングルーム",
    value: "海老名セレモニーホール 1階リビングルーム",
  },
  {
    label: "海老名セレモニーホール 2階式場さつき・3階式場けやき",
    value: "海老名セレモニーホール 2階式場さつき・3階式場けやき",
  },
  {
    label: "湘南大磯ホール 式場黒松",
    value: "湘南大磯ホール 式場黒松",
  },
  {
    label: "町田鶴川ファミリーホール 式場",
    value: "町田鶴川ファミリーホール 式場",
  },
  {
    label: "相模ファミリーホール 2階式場絆の間",
    value: "相模ファミリーホール 2階式場絆の間",
  },
  {
    label: "相模ファミリーホール 3階式場癒しの間",
    value: "相模ファミリーホール 3階式場癒しの間",
  },
  {
    label: "相模ファミリーホール リビングルーム",
    value: "相模ファミリーホール リビングルーム",
  },
  {
    label: "相模原会館 3階彩雲・紫雲",
    value: "相模原会館 3階彩雲・紫雲",
  },
  {
    label: "相模原会館 5階プルミエール",
    value: "相模原会館 5階プルミエール",
  },
  {
    label: "相模原会館 リビングルーム雅・優",
    value: "相模原会館 リビングルーム雅・優",
  },
  {
    label: "相模原市営斎場 大式場",
    value: "相模原市営斎場 大式場",
  },
  {
    label: "相模原市営斎場 小式場",
    value: "相模原市営斎場 小式場",
  },
  {
    label: "相模斎場 1階式場",
    value: "相模斎場 1階式場",
  },
  {
    label: "相模斎場 3階式場",
    value: "相模斎場 3階式場",
  },
  {
    label: "茅ヶ崎市斎場 全室利用",
    value: "茅ヶ崎市斎場 全室利用",
  },
  {
    label: "茅ヶ崎市斎場 半室利用",
    value: "茅ヶ崎市斎場 半室利用",
  },
  {
    label: "藤沢市斎場 大ホール",
    value: "藤沢市斎場 大ホール",
  },
  {
    label: "藤沢市斎場 小ホール",
    value: "藤沢市斎場 小ホール",
  },
  {
    label: "西湘ホール ビジテーションルーム",
    value: "西湘ホール ビジテーションルーム",
  },
  {
    label: "西湘ホール リビングルーム",
    value: "西湘ホールリビングルーム",
  },
  {
    label: "西湘ホール 式場",
    value: "西湘ホール 式場",
  },
  {
    label: "南多摩総合ホールさくら(お別れ式利用)",
    value: "さくら(お別れ式利用)",
  },
  {
    label: "ファミリーホール聖蹟桜ヶ丘ビジテーションルーム(お別れ式利用)",
    value: "ビジテーションルーム(お別れ式利用)",
  },
  {
    label: "ファミリーホール日野本町ご安置室(お別れ式利用)",
    value: "ご安置室共通",
  },
  {
    label: "日野会館高倉総合ホール小式場かすみ(お別れ式利用)",
    value: "小式場かすみ（お別れ式利用）",
  },
  {
    label: "ご安置室（共通）",
    value: "family_hall_hinohonmachi_mortuary_room",
  },
  {
    label: "湘南大磯ホール 小式場 ",
    value: "湘南大磯ホール 小式場",
  },
  {
    label: "座間ホール 2階小ホール ",
    value: "座間ホール 2階小ホール",
  },
  {
    label: "茅ヶ崎南口ファミリーホール　式場",
    value: "茅ヶ崎南口ファミリーホール　式場",
  },
]
